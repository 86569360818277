import React from "react"
import Layout from "../../../components/layout"
import { Link } from "gatsby"
import track from "../../../components/track"
import savingsCard from "../../../images/savings-card.png"

const Savings = () => {

  const pageTitle = 'Savings';
  const pageDescription = "Save on your MOVANTIK prescription today with the Savings Card and more. Get help treating opioid-induced constipation.";
  const pageName = "Savings";

  return (
    <Layout pageTitle={pageTitle} pageDescription={pageDescription} pageType="hcp" pageSection="hcp-savings" pageClass="savings"  pageName={pageName}>
      <div className="page-title">
        <div className="container">Savings</div>
      </div>
      <div className="container">
        <h1>Eligible patients can save with MOVANTIK</h1>
      </div>
      <div className="headline-divider"></div>
      <div className="container">
        <div className="columns two">
          <div className="column left-column">
            <h3>With the MOVANTIK Savings Card, eligible commercially insured patients can save on their MOVANTIK prescription and receive it for less than the cost of OTC laxatives.*</h3>
            <Link to="/hcp/savings/print/" id="patient-savings-btn-print" className="button tall" onClick={() => track("patient Savings page", "button click", "DTC Savings-Print the MOVANTIK Savings Card")}>
              <div className="btn-text">Access the MOVANTIK<br /><span className="bold">Savings Card</span></div>
              <div className="btn-arrow"></div>
            </Link>
            <img src={savingsCard} alt="Savings Card" className="card chart reposition" />
          </div>
        </div>
      </div>
      {/* <div className="callout">
        <div className="container">
          <p className="callout-large">TEXT <span className="yellow">[MSAVE]</span> to <span className="yellow">[89219]</span> to&nbsp;learn&nbsp;more<sup>‡</sup></p>
        </div>
      </div> */}
      <div className="container">
        <div className="end-matter">
          <div className="footnotes">
            <div className="footnote asterisk">*Subject to complete eligibility rules; restrictions apply. OTC laxatives include Colace<sup>&reg;</sup>, Dulcolax<sup>&reg;</sup>, ex-lax<sup>&reg;</sup>, MiraLAX<sup>&reg;</sup>, and Senokot<sup>&reg;</sup>. Product names are registered trademarks of their respective owners. Pricing comparisons do not imply comparable efficacy, safety, or FDA-approved indications.</div>
            {/* <div className="footnote"><sup>&dagger;</sup>Over the counter laxatives include Colace<sup>&reg;</sup>, Dulcolax<sup>&reg;</sup>, <span className="nowrap">ex-lax<sup>&reg;</sup>,</span> MiraLAX<sup>&reg;</sup>, <span className="nowrap">Peri-Colace<sup>&reg;</sup>,</span> Senokot<sup>&reg;</sup>, and <span className="nowrap">Senokot-S<sup>&reg;</sup></span>. Product names are registered trademarks of their respective owners.</div>
            <div>Pricing comparisons do not imply comparable efficacy, safety, or FDA-approved indications.</div> */}
            {/* <div className="footnote"><sup>&Dagger;</sup>By texting [MSAVE] to [89219], you agree to receive recurring automated MOVANTIK copay savings program messages from Valinor Pharma, LLC. Consent to receiving SMS messages is not a requirement to participate in the MOVANTIK Savings Program and you may opt out at any time. Message &amp; data rates may apply. Message frequency varies. Text STOP to opt out. Text HELP for help. For SMS Terms, please see <Link to="/copaysavingsprogram/smsterms/" id="patient-savings-link-terms" onClick={() => track("patient Savings page", "link click", "DTC Savings-Link to Savings terms")}>https://www.movantik.com/copaysavingsprogram/smsterms</Link>. For more information, visit <a href="https://www.movantik.com/privacy-policy/" target="_blank" id="patient-savingsterms-link-privacy">https://www.movantik.com/privacy-policy/</a>. The above excludes text messaging originator <span className="nowrap">opt-in</span> data and consent; this information will not be shared with any third parties.</div> */}
          </div>

          <div className="rules">
            <div className="rule-section">
                <p><span className="bold">PATIENT INSTRUCTIONS:</span> Present this offer to your pharmacist along with a valid prescription for MOVANTIK<sup>®</sup> (naloxegol) Tablets. Please call <a href="tel:3128960135">312-896-0135</a> with questions and for full eligibility details.</p>
                <p><span className="bold">TERMS AND CONDITIONS:</span> Offer good for patients who meet eligibility criteria purchasing a 30-, 60-, or 90-day supply. Eligible commercially insured patients will pay as little as <span className="bold">$0 for their monthly prescription,</span> subject to a maximum benefit limit. Eligible uninsured (cash-paying) patients will receive savings on eligible out-of-pocket costs subject to a maximum benefit limit.</p>
                <p>This program provides eligible patients with assistance to reduce out-of-pocket costs. By using this offer, patient and pharmacist understand and agree to comply with these terms and conditions. Only eligible U.S. residents may use this offer at participating pharmacies and may not redeem this offer at government-subsidized clinics. Offer user must be at least 18 years of age to use for themselves or on behalf of a minor. Patient age or insurance restrictions may apply.</p>
                <p><span className="bold">Restrictions:</span> No substitutions are permitted. Offer eligible only with valid prescription, has no cash value, and cannot be combined with any free trial, discount, prescription savings card, or other offer. This offer is not insurance. Valid only for patients with commercial insurance. This offer is NOT valid for prescriptions eligible to be reimbursed in whole or in part by Medicaid, Medicare (including Medicare Advantage and Part D plans), Veterans Affairs (VA), Department of Defense (DOD) programs or TriCare, or any other federally or state funded healthcare benefit program, or by commercial plans or health or pharmacy benefit programs that reimburse for the entire cost of the prescription drug or prohibit offer’s use. Medicare Part D enrollees who are in the prescription drug coverage gap are not eligible for offer. <span className="bold">This offer is not transferable and is limited to one offer per person.</span> Void were prohibited by law, taxed, or restricted. It is illegal to sell, purchase, trade, or counterfeit, or offer to sell, purchase, trade, or counterfeit the offer.</p>
                <p>Valinor Pharma, LLC, reserves the right to rescind, revoke, or amend the offer at any time without notice.</p>
                <p><span className="bold">Pharmacist instruction for a patient with an eligible Third-Party Payer:</span> Submit the claim to the primary Third-Party Payer first, then submit the balance due as a Secondary Payer using Secondary Payer COB [coordination of benefits] with patient responsibility amount and a valid Other Coverage Code (eg, 8 or 3).</p>
                <p><span className="bold">Pharmacist instruction for a cash-paying patient:</span> Submit this claim as a Primary Payer using a valid Other Coverage Code (eg, 1).</p>
                <p><span className="bold"><em>For any questions regarding online processing, call the Pharmacy Help Desk at <a href="tel:3128960135">312-896-0147</a>.</em></span></p>
            </div>
          </div>
          
        </div>
      </div>
    </Layout>
  )
}

export default Savings
